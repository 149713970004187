@import '@nerdwallet/base-styles/styles';

// superscript
.nds-sup {
  @include position-relative;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5em;
}
